/* fonts.css */

@font-face {
  font-family: 'pubg-sans';
  src: url('./font/PUBG\ SANS.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'monument';
  src: url('./font/MonumentExtended-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'bai-Italic';
  src: url('./font/BaiJamjuree-BoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'bai-exl';
  src: url('./font/BaiJamjuree-ExtraLight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'bai-Bold';
  src: url('./font/BaiJamjuree-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'bai-exl-I';
  src: url('./font/BaiJamjuree-ExtraLightItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'bai-L-I';
  src: url('./font/BaiJamjuree-LightItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'bai-L';
  src: url('./font/BaiJamjuree-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'bai-M';
  src: url('./font/BaiJamjuree-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'bai-M-I';
  src: url('./font/BaiJamjuree-MediumItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'bai-sb';
  src: url('./font/BaiJamjuree-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'bai-sbi';
  src: url('./font/BaiJamjuree-SemiBoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'bai-reg';
  src: url('./font/BaiJamjuree-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'bai-light';
  src: url('./font/BaiJamjuree-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}