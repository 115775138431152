.grid-cooontainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 1%;
  }
  
  .grid-rooow {
    display: flex;
    background-color: #ffffff;
    width: 60%;
    margin-top: 2%;
  }
  
  .grid-iteeem {
    flex: 5;
    border: 1px solid #ffffff;
    padding: none;
    margin: none;
    background-color: transparent;
    border: rgb(255, 255, 255);
    border-color: white;
    margin-left: 25%;
    margin-right: -25%;
  }

  .rob-parrr {
    margin-top: 15%;
    max-width: 100%;
    width: 100%;
  }

  .fantech-parrr {
    margin-top: 10%;
    max-width: 100%;
    width: 80%;
    margin-left: -25%;
  }

  .rog-parrr {
    max-width: 100%;
    width: 100%;
  }

  .taters-parrr {
    max-width: 100%;
    width: 80%;
    margin-top: 10%;
  }

  .con-parrr {
    max-width: 100%;
    width: 90%;
    margin-left: -15%;
    margin-top: 5%;
  }

  .ari-parrr {
    max-width: 100%;
    width: 90%;
    margin-left: -15%;
    margin-top: 10%;
  }

  .razer-parrr {
    margin-top: 0%;
    max-width: 100%;
    width: 5cm;
    margin-left: -20%;
  }

  .parrr {
    align-items: baseline;
    max-width: 100%;
    width: 50%;
    margin-left: 5%;
  }

  .nparrr {
    align-items: baseline;
    max-width: 100%;
    width: 50%;
    margin-left: 20%;
  }

  .gparrr {
    align-items: baseline;
    max-width: 100%;
    width: 50%;
    margin-left: 15%;
  }

  .sparrr {
    align-items: baseline;
    max-width: 100%;
    width: 70%;
    margin-top: 10%;
    margin-left: 5%;
  }
  
  @media (max-width: 768px) {
    .grid-rooow {
      display: flex;
      background-color: #ffffff;
      width: 80%;
      margin-left: 0%;
    }
  }