body {
  background: black;
}

.main-cont {
  display: flex;
  justify-content: center;
  color: white; /* Add some padding to the container */
  padding-top: 10%;
  text-align: center;
  margin-top: -15%;
  background-color: transparent;
}
.text-cont{
  width: 100%;
  background-color: transparent;
}

.cont-header {
  margin-top: -5%;
  margin-bottom: 40px;
  font-size: 80px;
}

.cont-desc {
  max-width: 100%;
  font-family: 'bai-light';
  text-align: justify;
  font-size: 20px;
  width: 30%;
  margin-left: 35%;
}

.img_formcontainer{
  display: flex;
  margin-top: -7%;
  margin-bottom: 150px;
  height: 100%;
  justify-content: center;
  width: 100%;

}

.card-container {
  position: relative;
  width: 100%;
  height: 537px;
  padding-left: 30px;
}

.character {
  padding: 0;
  position: absolute;
  margin-left: 19px;
  left: 40%; /* Adjust this value to move the image to the right */
  width: 46%;
  height: auto;
  object-fit: cover;
  z-index: 1; /* Higher z-index to be on top */
}

.cont-image {
  padding: 0;
  margin-top: 20px;
  margin-left: 50px;
  margin-top: -18%;
  margin-left: -30%;
  width: 165%;
  height: auto;
  object-fit: cover;
  z-index: 1; /* Higher z-index to be on top */
}

.character-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0; /* Lower z-index to be below */
}

.form-container {
  height: 50%;
  max-width: 30%;
  flex: 1;
  background-color: rgba(46, 46, 46, 0.822);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  margin-top: -5%;
  margin-left: 35%;
  margin-bottom: 10%;
}

.form-header-text {
  margin-left: 5%; 
  color: white;
}

.form-content {
  width: 100%;
  margin-right: 5%;
}

.form-content h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
}

.form-content .login-text {
  color: #562572;
}

.form-content form {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
}

.form-content input {
  margin-bottom: 15px;
  padding: 10px;
  border: none;
  border-radius: 0px;
}

.form-content input, .form-content textarea {
  background-color: rgb(0, 0, 0);
  color: #ffffff;
}


.form-content button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: rgb(62, 12, 128);
  color: #fff;
  cursor: pointer;
  margin-top: 5%;
  margin-bottom: 10%;
}

.form-content button:hover {
  background-color: rgb(37, 4, 65);
}

.form-content p {
  text-align: center;
  font-size: 0.9em;
}

.socials {
  margin-bottom: 10%;
}

@media (max-width: 768px) {
  .socials {
    margin-top: 5%;
     margin-bottom: 15%;
     width: 100%;
     justify-content: center;
  }

  .main-cont {
    width: 100%;
    display: block;
  }

  .cont-form {
      position: absolute;
  }

  .form-cont {
      position: absolute;
      width: 100%;
      margin-top: 110%;
  }

  .cont-header {
      font-size: 50px;
  }

  .img_formcontainer {
    width: 100%;
    margin-top: -10%;
    margin-left: 27%;
  }

  .card-container {
    display: none;
  }

  .form-content{
    display: block;
    text-align: center;
    height: 50%;
    width: 90%;
  }

  .form-container{
    max-width: 100%;
    width: 100%;
    margin-left: 0%;
  }


  .text-cont {
    width: 100%;
  }

  .cont-desc {
    width: 90%;
    font-size: 17px;
    margin-left: 5%;
  }
  
}


