.news1-containering {
    margin-top: 5%;
    margin-bottom: -15%;
}

.news1-imgs {
    width: 100%;
}

.news2-imgs {
    width: 50%;
    margin-left: 25%;
    margin-top: 3%;
}

.date-header {
    font-size: 20px;
    font-family: 'bai-light';
    color: white;
    text-align: center;
    margin-top: -1%;
}


.div-article {
    margin-top: -5%;
    margin-bottom: 25%;
}

.news1-header {
    font-size: 50px;
    color: white;
    text-align: center;
    margin-top: 10%;
    margin-left: 20%;
    width: 60%;
}

.news1-body {
    font-size: 20px;
    font-family: 'bai-light';
    color: white;
    margin-left: 25%;
    width: 50%;
    margin-top: 5%;
}

.news2-body {
    font-size: 20px;
    font-family: 'bai-light';
    color: white;
    margin-left: 25%;
    width: 50%;
    margin-top: 5%;
}

@media (max-width: 768px) {
    .news1-body {
        font-size: 15px;
        font-family: 'bai-light';
        color: white;
        width: 90%;
        margin-top: 10%;
        margin-left: 5%;
    }

    .news2-body {
        font-size: 15px;
        text-align: left;
        font-family: 'bai-light';
        color: white;
        width: 90%;
        margin-top: 5%;
        margin-left: 5%;
    }

    .news1-imgs {
        margin-top: 10%;
    }

    .news2-imgs {
        width: 90%;
        margin-left: 5%;
    }

    .news1-header {
        font-size: 25px;
        margin-left: -0%;
        width: 100%;
        color: white;
        text-align: center;
    }

    .date-header {
        font-size: 15px;
        font-family: 'bai-light';
        color: white;
        text-align: center;
        margin-top: -0%;
    }
}
