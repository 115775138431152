.counts-container {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .counts-container.visible {
    opacity: 1;
  }
  .animatedCounter {
    /* Default font size */
    font-size: 40px;
  }
  
  @media screen and (min-width: 768px) {
    /* Adjust font size for tablets */
    .animatedCounter {
      font-size: 20px;
    }
  }
  
  @media screen and (min-width: 1024px) {
    /* Adjust font size for desktops */
    .animatedCounter {
      font-size: 45px;
    }
  }
  
  /* Add more media queries as needed */
  