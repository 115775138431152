.who-div {
  background-color: rgb(255, 255, 255);
  padding: 10%;
  margin-top: -5%;
  margin-bottom: 0%;
}

.grid-containerc {
display: grid;
grid-template-columns: 1fr 1fr;
gap: 20px;
align-items: center;
background: rgb(255, 255, 255);    
}

.text-content {
padding-right: 20px;
background-color: white;
}

.image-content {
display: flex;
justify-content: center;
align-items: center;
height: 100%;
width: 100%;
}

.who-image {
  max-width: 100%;
  margin-left: 130px;
  height: auto;
}

.who {
padding-top: 5%;
text-align: left;
margin-bottom: 0%;
color: rgb(0, 0, 0);
font-family: 'bai-light';
font-size: 80px;
margin-top: -3%;
/* opacity: 0;
transform: translateX(-50%);
animation: slideInText 2s ease-in-out forwards;
animation-delay: 2.5s; */
}

.we_are {
  padding-top: 2%;
  text-align: left;
  margin-top: 0%;
  margin-bottom: 0%;
  color: rgb(0, 0, 0);
  font-family: 'bai-bold';
  font-size: 80px;
  margin-top: -3%;
  /* opacity: 0;
  transform: translateX(-50%);
  animation: slideInText 2s ease-in-out forwards;
  animation-delay: 2.5s; */
}

.des {
  text-align: left;
  margin-top: 2%;
  margin-left: 0%;
  font-family: 'bai-reg';
  font-size: 20px;
  /* opacity: 0;
  transform: translateX(-50%);
  animation: slideInText 2s ease-in-out forwards;
  animation-delay: 3s; */
}

.carousel-box {
  position: relative;
  max-width: 760px; /* Set the maximum width of the carousel */
  margin: 0 auto; /* Center the carousel horizontally */
  border: 5px solid #ffffff; /* Add a border with a light gray color */
  border-radius: 25px; /* Add a border radius for rounded corners */
  overflow: hidden; /* Hide overflow content */
  height: auto;
  background-color: transparent;
  /* opacity: 0;
  transform: translateX(-50%);
  animation: slideInText 2.5s ease-in-out forwards;
  animation-delay: 4.5s; */
  box-shadow: #333;
  }

.carousel-box .slide img {
  width: 150%; /* Make images fill the width of their container */
  height: auto; /* Maintain aspect ratio */
  max-height: 350px; /* Limit the maximum height of images */
  object-fit: contain;
  background-color: white;
}

.carousel-box .carousel .thumbs-wrapper {
  display: none; /* Hide the thumbnails */
}

/* Positioning the control dots */
.carousel-box .carousel .carousel-slider .control-dots {
  position: absolute;
  bottom: -20px; /* Adjust this value to your preference */
  left: 50%; /* Align horizontally in the center */
  transform: translateX(-50%); /* Center horizontally */
}

/* Style for the control dots */
.carousel-box .carousel .carousel-slider .control-dots .dot {
  margin: 0 5px; /* Adjust spacing between dots */
  background: #ccc; /* Default dot color */
  width: 10px; /* Dot width */
  height: 10px; /* Dot height */
  border-radius: 50%; /* Make dots circular */
  cursor: pointer; /* Add cursor pointer */
}

/* Style for the active dot */
.carousel-box .carousel .carousel-slider .control-dots .dot.selected {
  background: #333; /* Active dot color */
}

.image-content {
  background: transparent;
}



@keyframes slideIn {
from {
  opacity: 0;
  transform: translateX(-100%);
}
to {
  opacity: 1;
  transform: translateX(0);
}
}

@keyframes slideInText {
from {
  opacity: 0;
  transform: translateX(-50%);
}
to {
  opacity: 1;
  transform: translateX(0);
}
}


@media (max-width: 768px) {
  .grid-containerc {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    align-items: center;
    background: rgb(255, 255, 255);
    }

    .carousel-box {
      margin-top: 55%;
    }

    .image-content {
      margin-top: 15%;
      margin-bottom: 15%;
    }    

    .who {
      font-size: 50px;
    }
    .we_are {
      font-size: 50px;
    }

    .carousel-box {
      margin-top: 55%;
      position: absolute;
      max-width: 100%;
      width: 95%;
      margin-bottom: 20%;
    }

    .des {
      width: -115%;
    }
}



