/* News Container */
.news-container {
    display: flex;
    flex-direction: column;
    padding: 15%;
    background-color: #1d1c1c;
    color: #fff;
    height: 100%;
       
  }

  
  @media (min-width: 768px) {
    .news-container {
      flex-direction: column;
      margin-top: 15%; 
    }
    
    
  }
  
  /* News Item */
  .news-item {
    display: flex;
    flex-direction: column;
    background-color: #161616;
    padding: 20px;
    margin: 10px 0;
    border-radius: 8px;
  }
  
  @media (min-width: 768px) {
    .news-item {
      flex-direction: row;
      align-items: center;
      margin: 10px 0;
    }

    
    
  }
  
  /* Text Container */
  .text-container {
    margin-left: 5%;
    margin-right: 5%;
  }
  
  /* News Title */
  .news-title {
    font-size: 1rem;
    text-align: left;
    color: #ffffff;
    margin-bottom: -0%;
    margin-top: -0%;
  }

  .cut {
    display: none;
  }
  
  .news-container {
    flex-direction: column;
    margin-top: -5%;
  }
  
  /* News Description */
  .news-description {
    font-size: 13px;
    color: #ccc;
    font-family: 'bai-reg';
    font-style: bold;
  }

  .news-date {
    font-family: 'bai-light';
    font-size: 12px;
  }
  
  
  /* Button */
  .news-button {
    padding: 10px 20px;
    border: none;
    background-color: #444;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .news-button:hover {
    background-color: #666;
  }
  
  /* Image Placeholder */
  .image-placeholder {
    width: 100%;
    height: auto;
    background-color: #333;
    border-radius: 5px;
    margin-top: 15px;
  }

  .news-imgs {
    max-width: 500%;
    width: 100%;
    height: auto;
}


.news-header {
  color: rgb(255, 255, 255);
  font-size: 40px;
}
  
  @media (min-width: 768px) {
    .image-placeholder {
      margin-top: 0;
      width: auto;
      height: auto;
    }
    

    .news-header {
      color: rgb(255, 255, 255);
      font-size: 80px;
      margin-top: -13%;
    }

    .news-imgs {
        max-width: 200%;
        width: 100%;
        height: auto;
    }
    
    .news-container {
      flex-direction: column;
      margin-top: 0%; 
    }
    
    .cut {
      display: contents;
    }
  }
  