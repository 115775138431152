.comp-img {
    margin-top: 5%;
}

.mage {
    position: absolute;
}

/* .res-imgs1 {
    max-width: 100%;
    width: 40%;
    margin-left: -5%;
    margin-bottom: 20%;
    margin-top: -20%;
    /* opacity: 0;
    transform: scale(0.9);
    animation: fadeInScale 1s ease-in-out forwards;
    animation-delay: 3s; */

/* .res-imgs2 {
    max-width: 100%;
    width: 40%;
    margin-top: -25%;
    margin-left: 55%;
    /* opacity: 0;
    transform: scale(0.9);
    animation: fadeInScale 1s ease-in-out forwards;
    animation-delay: 3s; */

.res-imgs3 {
    max-width: 100%;
    width: 30%;
    margin-bottom: 30%;
    margin-left: 45%;
}

.res-imgs4 {
    max-width: 100%;
    width: 30%;
    margin-top: -30%;
    margin-bottom: 100%;
    margin-left: 70%;
}


/* @media (max-width: 672px) {
    .res-imgs1 {
        margin-top: -80%;
        margin-left: 35%;
        width: 80%;
    }

    .res-imgs2 {
        margin-top: -80%;
        margin-left: -25%;
        width: 80%;
    }
} */
