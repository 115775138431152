/* Basic styles */
body {
    margin: 0;
    font-family: 'bai-bold';
  }

html {
  scroll-behavior: smooth;
}
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000000;
    padding: 1px 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    border-bottom: #180036;
  }
  
  .navbar-logo {
    display: flex;
    justify-content: center;
    padding: 1%;
    align-items: center; /* This centers the logo vertically as well */
    }

    .logo {
    max-width: 500%;
    width: 150%;
    margin-left: -30%;
    margin-top: -35%;
    margin-bottom: 4%;
    }
  
  .navbar-links {
    display: flex;
    list-style: none;
    font-family: 'bai-reg';
    font-size: 15px;
    margin-top: 2%;
    margin-right: 25%;
    margin-bottom: 0%;
    margin-left: 20%;
  }

  .navbar-links::after {
    display: flex;
    list-style: none;
    font-family: 'bai-reg';
    font-size: 15px;
    margin-top: 2%;
    margin-right: 30%;
    margin-bottom: 0%;
    margin-left: 20%;
    transition: ease-in-out 1s;
  }
  
  .navbar-links li {
    margin: 0 20px;
  }
  
  .navbar-links a {
    color: #fff;
    text-decoration: none;
    transition: ease-in-out 1s;
  }

  .navbar-links a:hover {
    color: #fff;
    text-decoration: underline solid 3px;
    text-underline-position: under;
  }
  
  .dropbtn a {
    color: #fff;
    text-decoration: underline solid 3px;
    text-underline-position: under;
  }

  .dropbtn a:hover {
    color: #fff;
    text-decoration: underline solid 3px;
    text-underline-position: under;
  }
  
  
  .dropdown {
    position: relative;
  }
  

  .dropbtnn {
    background-color: #000000;
    color: white;
    border: none;
    cursor: pointer;
    margin-bottom: -10%;
    font-size: 15px;
    font-family: 'bai-reg';
  }

  .dropbtn {
    background-color: #000000;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: -3%;
    margin-bottom: 40px; 
    font-size: 15px;
    font-family: 'bai-reg';
  }
  
  .dropdown-content {
    border-radius: 3%;
    display: none;
    position: absolute;
    background-color: #2b2b2b;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a:hover {
    text-decoration: none;
  }

  .dropdown-content a {
    color: rgb(255, 255, 255);
    font-size: 15px;
    padding: 15px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {
    background-color: #2e1065;
    border-radius: 5%;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
    margin-top: -20%;
  }
  
  /* Responsive styles */
  .menu-toggle {
    display:flex;
    flex-direction: column;
    cursor: pointer;
    margin: 5%;
    display: none;
  }
  
  .open {
    margin-right: 100%;
    padding-left: -50%;
  }

  .menu-toggle .bar {
    height: 2px;
    width: 20px;
    background-color: white;
    margin: 3px 0;
    padding-right: -50%;
  }
  
  @media (max-width: 768px) {
    .navbar-links {
      display: none;
      flex-direction: column;
      width: 100%;
      margin-top: 10%;
      margin-left: 17%;
    }
  
    .navbar-links.open {
      display:flex;
      margin-left: -10%;
      margin-top: 5%;
    }
    
    .logo {
        width: 30%;
        margin-top: -10%;
        position: absolute;
        margin-left: -15%;
    }

    .navbar-logo {
        display: flex;
        justify-content: center;
        margin-top: -12%;
        align-items: center; /* This centers the logo vertically as well */
    }

    .menu-toggle {
      display: flex;
      margin-right: 20%;
      text-align: center;
    }
  
    .navbar-links li {
      text-align: center;
      margin: 15px 0;
    }
  }
  
  .home-class {
    background-color: #000000;
    color: white;
    margin-top: 0%;
    margin-bottom: 0px; 
    font-size: 15px;
    font-family: 'bai-reg';
  }

  .who-we-are-class{
    background-color: #000000;
    color: white;
    margin-top: 0%;
    margin-bottom: 0px; 
    font-size: 15px;
    font-family: 'bai-reg';
  }