.env-up {
    color: white;
    font-family: 'bai-bold';
    font-size: 50px;
    text-align: center;
    padding-top: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
}


@media only screen and (max-width: 768px) {
    .env-up {
        color: white;
        font-family: 'bai-bold';
        font-size: 25px;
        margin-bottom: -90%;
    }
    .company_events {
        margin-bottom: -100%;
    }
}