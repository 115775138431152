.grid-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    margin-left: 5%;
    margin-top: -5%;
  }

  .part-header {
    width: 100%;
    text-align: center;
  }
  
  .grid-row {
    display: flex;
    background-color: transparent;
    margin-top: -7%;
    margin-right: 5%;
    margin-left: 25%;
  }

  .partnerss {
    color: rgb(0, 0, 0);
    font-size: 80px;
    text-align: center;
    font-family: 'bai-bold';
  }

  .par3 {
    max-width: 100%;
    width: 100%;
    justify-content:center;
    margin-left: -120%;
    margin-top: 45%;
  }

  .par2 {
    max-width: 100%;
    width: 100%;
    justify-content:center;
    margin-left: -30%;
    margin-top: 40%;
  }

  .br {
    color: rgb(0, 0, 0);
    font-size: 80px;
    font-family: 'bai-bold';
    text-align: center;
  }

  .long-term {
    font-family:'bai-bold';
  }
  
  .grid-item {
    flex: 5;
    background-color: transparent;
    border: none;
    border-radius: 5%;
    padding: 5%;
    margin-left: 0%;
    margin-right: -5%;
    padding-right: 6%;
  }

  .defi {
    font-size: 20px;
    color: rgb(0, 0, 0);
    font-family: 'bai-light';
    text-align: left;
    margin-top: -7%;
    margin-bottom: 10%;
  }

  .br {
    color: rgb(0, 0, 0);
    font-size: 40px;
    font-family: 'bai-light';
  }

  .par {
    max-width: 100%;
    width: 100%;
    justify-content:center;
    margin-left: -90%;
    margin-top: 40%;
  }

  .partt {
    max-width: 100%;
    width: 80%;
    justify-content:center;
    margin-left: -90%;
    margin-top: 25%;
  }

  .pba-par {
    margin-top: 13%;
    max-width: 100%;
    width: 100%;
    justify-content:center;
    margin-left: -85%;
    margin-top: 50%;
  }

  .pba-pare {
    max-width: 150%;
    width: 150%;
    justify-content:center;
    margin-left: -100%;
    margin-top: 10%;
  }

  .grid-row2 {
    display: flex;
    background-color: transparent;
    margin-top: -7%;
    margin-right: 5%;
    margin-left: 41%;
    width: 30%;
  }

  .div-mai {
    margin-top: -15%;
    background-color: white;
  }

  @media (max-width: 768px) {
    .grid-row {
      display: flex;
      background-color: #ffffff;
      width: 75%;
      margin-left: 20%;
    }
    .grid-container {
      width: 50%;
      margin-left: 0%;
    }

    .partnerss {
      font-size: 25px;
      margin-bottom: -15%;
      text-align: center;
      width: 100%;
      margin-top: 10%;
    }

    .defi {
      width: 180%;
    }

    .div-mai {
      padding-top: 5%;
    }

    .br {
      text-align: center;
      font-size: 15px;
      width: 100%;
      margin-top: 20%;
    }
    
  }
  