.yt-div {
    width: 100%;
}

@media (max-width: 768px) {
    .container-yt1 {
        width: 20%;
    }

    .yt-div {
        width: 10%;
    }
}