.div-cast {
    width: 70%;
    justify-content: center;
    margin-top: 3%;
    margin-left: 15%;
}

.car-con1 {
    margin-bottom: 8%;
    margin-top: -5%;
}

.car-con2 {
    margin-bottom: 2%;
    margin-top: -5%;
}

.car-con3 {
    margin-bottom: -4%;
    margin-top: -5%;
}

.car-con4 {
    margin-bottom: -7%;
    margin-top: -5%;
}

@media (max-width: 768px) {
    .div-cast {
        width: 70%;
        justify-content: center;
        margin-top: 3%;
        margin-left: 15%;
    }
    
    .car-con1 {
        margin-bottom: 11%;
        margin-top: -5%;
    }
    
    .car-con2 {
        margin-bottom: -5%;
        margin-top: -5%;
    }
    
    .car-con3 {
        margin-bottom: -1%;
        margin-top: -5%;
    }
    
    .car-con4 {
        margin-bottom: -5%;
        margin-top: -5%;
    }
}