.parent {
    display: flex;
    justify-content: center; /* Center the cards horizontally */
  }
  
  .voh1 {
    margin-bottom: 20%;
    background-color: black;
    margin-top: -20%;
    padding-top: 5%;
  }
  
  .hove-cont {
    width: 85%;
    margin-top: 5%;
    margin-left: 7%;
  }
  
  .ev-img {
    padding: -5%;
  }
  
  .hove-arrow {
    width: 50px; /* Increase the width */
    height: 130px; /* Increase the height */
    z-index: 1;
  }
  
  .hove-arrow::before {
    font-size: 50px; /* Increase the font size of the arrow icon */
    color: #ffffff;
  }
  
  .hove-arrow:hover::before {
    color: #555;
  }
  
  
  .history1{
    color: white;
    font-size: 80px;
    margin-bottom: -3%;
    margin-top: 5%;
    margin-left: -5%;
    text-align: center;
  }
  
  .card {
    width: calc(33.33% - 140px); /* Adjust card width for different screen sizes */
    margin:  auto;
    margin-bottom: 15%;
    object-fit: fill;
    border: none;
    transform-style: preserve-3d;
    background-size: 60px 60px;
    background-position: 0 0, 0 0;
    background-color: rgba(9, 9, 9, 0.9);
    border-radius: 5px;
    position: relative;
    height: 42vh;
  }
  
  .card-title {
    font-family: 'bai-bold';
    padding-top: 2%;
  }
  
  .card:hover {
    background-position: -100px 100px, -100px 100px;
    transform: rotate3d(0.5, 0, 0, 10deg);
  }
  
  .content-box {
    background: rgba(9, 9, 9, 0.9);
    transition: all 0.5s ease-in-out;
    padding: 5px 15px 5px 15px;
    transform-style: preserve-3d;
  }
  
  .content-box .card-title {
    display: inline-block;
    color: white;
    font-size: calc(8px + 0.63vw);
    font-weight: bold;
    transition: all 0.5s ease-in-out;
    transform: translate3d(0px, 0px, 50px);
  }
  
  .content-box .card-title:hover {
    transform: translate3d(0px, 0px, 60px);
  }
  
  .content-box .card-content {
    margin-top: 10px;
    font-size: calc(5px + 0.40vw);
    text-align: left;
    color: #f2f2f2;
    transition: all 0.5s ease-in-out;
    transform: translate3d(0px, 0px, 30px);
  }
  
  .content-box .card-content:hover {
    transform: translate3d(0px, 0px, 60px);
  }
  
  .content-box .see-more {
    cursor: pointer;
    margin-top: 1rem;
    display: inline-block;
    font-weight: 900;
    font-size: 9px;
    text-transform: uppercase;
    color: rgba(9, 9, 9, 0.9);
    background: transparent;
    padding: 0.5rem 0.7rem;
    transition: all 0.5s ease-in-out;
    transform: translate3d(0px, 0px, 60px);
  }
  
  .content-box .see-more:hover {
    transform: translate3d(0px, 0px, 60px);
  }
  
  .date-box {
    position: absolute;
    top: 20px;
    right: 30px;
    height: 60px;
    width: 100px;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.9);
    padding-top: 3%;
    transform: translate3d(0px, 0px, 80px);
    box-shadow: rgba(42, 42, 43, 0.2) 0px 17px 10px -10px;
  }
  
  .date-box span {
    display: block;
    text-align: center;
  }
  
  .date-box .month {
    color: rgba(255, 255, 255, 0.9);
    font-size: 10px;
    font-weight: 700;
    text-align: center;
  }
  
  .date-box .date {
    font-size: 15px;
    font-weight: 900;
    color: rgba(255, 255, 255, 0.9);
    text-align: center;
  }
  
  /* .separator {
      width: 0;
      height: 0;
      border-right: 100vw solid transparent;
      border-bottom: 13.9vw solid #ffff;
      margin-top: 13.9;
  } */
  
  /* Media Queries for Responsive Design */
  
  @media (max-width: 768px) {
    .card {
      width: calc(50% - 20px); /* Adjust card width for smaller screens */
    }
    .content-box .card-title {
      font-size: 18px;
    }
    
    .content-box .card-content {
      font-size: 12px;
    }
    .history1{
      color: white;
      font-size: 40px;
      text-align: center;
    }
  
    .hove-arrow {
      width: 50px; /* Increase the width */
      height: 150px; /* Increase the height */
      z-index: 1;
    }
    
    .hove-arrow::before {
      font-size: 50px; /* Increase the font size of the arrow icon */
      color: #ffffff;
    }
    
    .hove-arrow:hover::before {
      color: #555;
    }
    
  }
  
  @media (max-width: 576px) {
    .card {
      width: calc(100% - 20px); /* Adjust card width for mobile devices */
    }
  }
  