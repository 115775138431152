.smart-div {
    background-color: rgb(0, 0, 0);
    padding: 0;
    margin-top: 0%;
    margin-bottom: 5%;
}

.smart-img3 {
    width: 100%;
    justify-content: center;
}

.smart-img4 {
    width: 60%;
    justify-content: center;
}

.smart-img6 {
    width: 90%;
    justify-content: center;
}

.smart-img {
    width: 100%;
    margin-top: 0%;
}

.smart-img2 {
    width: 90%;
    margin-left: -27%;
    margin-top: -20%;
}

.smart-desc {
    font-family: 'bai-light';
    color: rgb(255, 255, 255);
    width: 50%;
    font-size: 20px;
    text-align: center;
    justify-content: center;
    margin-left: 25%;
    margin-top: 0%;
}

.smart-desc2 {
    font-family: 'bai-light';
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    justify-content: center;
    margin-top: -2%;
    margin-bottom: -5%;
    background-color: white;
    padding: 5%;
}

.smart-header {
    color: rgb(255, 255, 255);
    font-family: 'bai-bold';
    font-size: 50px;
    text-align: center;
    margin-top: 5%;
    padding-bottom: 10%;
}

.smart-header2 {
    color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
    font-family: 'bai-bold';
    font-size: 50px;
    text-align: center;
    margin-top: 5%;
    margin-bottom: -3%;
    padding-top: 3%;
    padding-top: 5%;
}

.smart-header3 {
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    font-family: 'bai-bold';
    font-size: 50px;
    text-align: center;
    margin-top: -1%;
    margin-bottom: -3%;
    padding-top: -10%;
}

.smart-box {
    width: 50%;
    margin-top: 15%;
    margin-bottom: -7%;
}

.smart-div2 {
    background: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    padding-top: 5%;
}

.smart-div3 {
    background: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    padding-bottom: 5%;
}

.smart-div4 {
    background: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    padding-top: 5%;
}

.smart-div5 {
    background: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    padding-bottom: 10%;
}

.caarrd {    
    box-sizing: border-box;
    width: 390px;
    height: 254px;
    background: transparent;
    border: 1px solid white;
    box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
    backdrop-filter: blur(6px);
    border-radius: 17px;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    font-weight: bolder;
    color: black;
    margin: 0.5%;
  }
  
  .caarrd:hover {
    border: 1px solid black;
    transform: scale(1.05);
  }
  
  .caarrd:active {
    transform: scale(0.95) rotateZ(1.7deg);
  }

@media (max-width: 768px) {
    .smart-div {
        width: 100%;
        padding-bottom: none;
        background-color: black;    
    }

    .smart-desc {
        width: 90%;
        margin-left: 5%;
        text-align: left;
        font-size: 15px;
    }

    .smart-desc2 {
        width: 80%;
        margin-left: 7%;
        margin-top: 5%;
        text-align: center;
        font-size: 12px;
    }

    .smart-header {
        color: rgb(255, 255, 255);
        font-family: 'bai-bold';
        font-size: 25px;
        text-align: center;
        margin-left: -0%;
    }

    .smart-header2 {
        color: rgb(255, 255, 255);
        font-family: 'bai-bold';
        font-size: 25px;
        text-align: center;
        margin-left: -0%;
    }

    .smart-box {
        margin-left: -37%;
        margin-top: -95%;
        width: 100%;
    }

}