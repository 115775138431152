.res {
  display: flex;
  justify-content: center;
  padding-bottom: 10%;
}

.res1 {
  display: flex;
  justify-content: center;
  padding-bottom: 20%;
}

.vices {
  color: rgb(0, 0, 0);
  font-size: 80px;
  text-align: center;
  padding-top: 10%;
}

.serv {
margin-bottom: 15%;
background-color: rgb(255, 255, 255);
}

.def {
color: rgb(0, 0, 0);
font-family: 'bai-light';
font-size: 20px;
text-align: center;
width: 50%;
margin-left: 25%;
text-shadow: -1px 1px 10px rgb(138, 134, 134)
}

.out-desc {
color: black;
width: 70%;
text-align: justify;
text-justify: distribute;
word-break: break-all;
margin-left: 15%;
margin-top: -5%;
font-size: 14px;
font-family: 'bai-light';
}

.ccard {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 400px;
  height: 300px;
  background-color: none;
  background: none;
  border: none;
  box-shadow: none;
  backdrop-filter: none;
  border-radius: 0px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-weight: bolder;
  color: rgb(61, 61, 61);
  margin: 0.2%;
  margin-top: 1%;
  /* opacity: 0;
  transform: translateX(-50%);
  animation: slideInText 2.5s ease-in-out forwards;
  animation-delay: 4.5s; */
}

.ccard:hover {
  border: 0px solid rgb(12, 12, 12);
  transform: scale(1.05);
}

.ccard:active {
  transform: scale(0.95) rotateZ(1.7deg);
}

.ponsive-imgs {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: -5%;
  transition: .5s ease;
  background-color: transparent;
}

.ccard:hover .overlay {
  opacity: 200%;
}

.text {
  display: none;
  color: rgb(0, 0, 0);
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.dialog {
  max-width: 500%;
  width: 100%;
}

.dialogContentContainer {
  display: flex;
  align-items: flex-start; /* Align items to the start to make text align with the top of images */
}

.dialogTitle {
  background-color: rgb(255, 255, 255);
  font-size: 150px;
  color: rgb(0, 0, 0); /* Dialog title text color */
  font-family: 'bai-bold';
  font-weight: bolder;
  height: auto;
}

.dialogCon {
  display: block;
  height: -100px;
  object-fit: fill;
  padding: none;
}

.dialogImg {
  max-width: 180%;
  width: 21%;
  position:relative;
  margin-top: -1%;
  margin-left: 8%;
}

.dialogContent {
  background-color: rgb(255, 255, 255);
  color: white; /* Dialog content text color */
  display: table-column;
  width: 96%;
  margin: none;
}

.dialogImages {
  display: flex;
  flex-direction: column; /* Ensure images stack vertically */
  margin: none;
   /* Add some space between images and text */
}

.dialogImage {
  max-width: 180%;
  width: 98%;
  height: -50%;
  object-fit: contain;
  position:relative;
  margin-left: 1%;
}

#alert-dialog-description {
  color: rgb(0, 0, 0); /* Dialog content text color */
  width: 82%;
  text-align: justify;
  font-family: 'bai-reg';
  margin-left: 8%;
  max-width: 100%;
  margin-bottom: 10px;
  font-size: 13px;
}

.dialogActions {
  background-color: rgb(255, 255, 255);
  padding: 8px 20px;
  display: flex;
  justify-content: center;
  margin: none;
}

.dialogButton {
  background-color: rgb(255, 255, 255);
  margin-left: 0px;
  color: rgb(81, 0, 156);
  font-family: 'bai-bold';
  
}

@media (max-width: 768px) {
  .serv {
    width: 100%;
  }

  .ccard {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 490px;
    height: 150px;
  }

  .def {
    color: rgb(0, 0, 0);
    font-family: 'bai-light';
    font-size: 15px;
    text-align: center;
    width: 80%;
    margin-left: 10%;
  }

  .res {
    padding-bottom: 20%;
  }

  .res1 {
    width: 67%;
    margin-left: 15%;
    padding-bottom: 30%;
  }

  .vices {
    color: rgb(0, 0, 0);
    font-size: 50px;
    text-align: center;
  }
  .dialogContent {
    margin-left: -5%;
  }
  .out-desc {
    color: black;
    width: 85%;
    text-align: justify;
    font-size: 8px;
    margin-left: 9%;
  }
}