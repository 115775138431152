.core-div {
    background-color: white;
    margin-top: -5%;
    justify-content: center;
    width: 100%;
}

.core-container {
    display: block;
    padding: 10px;
    background-color: white;
    padding-bottom: 10%;
    width: 30%;
    justify-content: center;
    margin-left: 30%;
  }

  .div-pos {
    width: 100%;
    margin-top: 10%;
  }

  .core-box {
    display: flex;
    margin: 10px;
    background-color: #f0f0f0;
    padding: 20px;
    width: 100%;
    text-align: center;
    box-shadow: 0 0 10px rgba(66, 66, 66, 0.1);
    box-sizing: border-box;
  }

  .divdiv {
    display: flex;
    width: 100%;
  }

  .compf {
    font-family: 'bai-light';
  }

  .comp {
    font-family: 'bai-light';
    text-align: center;
  }
  
  .core-header {
    text-align: center;
    font-size: 90px;
    font-family: 'bai-bold';
  }

  .core1 {
    width: 100%;
  }
  .core2 {
    width: 100%;
  }
  .core3 {
    width: 100%;
  }
  .core4 {
    width: 100%;
  }

  .firstN {
    font-family: 'bai-light';
  }

  .firstl {
    font-size: 24px;
    font-family: 'bai-bold';
  }

  .firstlN {
    font-size: 25px;
    margin-top: 2%;
  }

  .pos {
    font-size: 15px;
    font-family: 'bai-reg';
  }
  
  @media (max-width: 1200px) {
    .core-box {
      flex: 1 1 calc(33.33% - 20px);
    }
    .core-div {
        background-color: white;
        margin-top: -5%;
    }
    
  }
  
  @media (max-width: 900px) {
    .core-box {
      flex: 1 1 calc(50% - 20px);
    }
    .core-div {
        background-color: white;
        margin-top: -5%;
    }
    
  }
  
  @media (max-width: 768px) {
    .core-box {
      flex: 1 1 calc(100% - 20px);
      margin-left: -15%;
      width: 50%;
    }
    .core-div {
        background-color: white;
        margin-top: -10%;
    }
    .core-container {
        width: 78%;
        margin-left: 15%;
    }
    .div-pos {
        width: 100%;
        margin-top: 8%;
      }

      .pos {
        font-size: 11px;
        font-family: 'bai-reg';
      }

      .firstl {
        font-size: 13px;
        font-family: 'bai-bold';
      }
      .core-header {
        text-align: center;
        font-size: 60px;
        font-family: 'bai-bold';
        margin-top: 5%;
      }
    
  }
  