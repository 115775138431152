/* Base styles */
.video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    margin-top: 5.5%;
    top: 0;
    left: 0;
}

.dark-league {
    color: rgb(255, 255, 255);
    font-family: 'bai-Bold';
    font-size: 90px;
    font-weight: bolder;
    margin-left: 25%;
    margin-top: -5%;
}



.studios {
    color: rgb(255, 255, 255);
    font-size: 90px;
    font-family: 'bai-exl';
    margin-left: 25%;
    margin-top: -10%;
}



.light {
  color: rgb(255, 255, 255);
  font-weight: 900;
}

.her {
  font-weight: 900;
}

.to {
  color: rgb(0, 0, 0);
}

.tag-line {
    font-family: 'bai-reg';
    color: rgb(255, 255, 255);
    font-size: 40px;
    margin-top: -10%;
    margin-left: 7%;
    text-align: center;
}

.hero-containering {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0%;
    position: relative;
    overflow: hidden;
    height: 95vh;
    padding-bottom: 0%;
    background-position: center;
    width: auto;
    height: 100vh;
    background-color: rgb(0, 0, 0);
}

.hero-contents {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1300px;
}

.text-contents {
    flex: 1;
    text-align: left;
    padding-right: 20px; /* Adjust as needed */
}

.text-contents .tag-line {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-in-out forwards;
}
  
  .text-contents .tag-line {
    animation-delay: 0.8s;
  }

.image-contents {
    flex: 1;
    text-align: center;
    margin-bottom: -16.8%;
    margin-top:-27%;
    margin-left: -30%;
    margin-right: -20%;    
}

.responsive-imgs {
    max-width: 300%;
    width: 195%;
    height: auto;
}

.abt {
    color: white;
    margin-left: -4%;
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInScale {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @media only screen and (max-width: 480px) {
    .dark-league {
      color: rgb(255, 255, 255);
      margin-left: -20%;
      margin-top: 20%;
      font-size: calc(30px + 10vw);
  }
  .studios {
    font-size: calc(30px + 10vw);
    margin-left: -50%;
    margin-top: -15%;
  }
  
  .tag-line {
    font-size: calc(10px + 2vw);
    margin-left: -30%;
    margin-top: -20%;
    margin-bottom: 100%;
    font-weight: bold;
  }
  
  .image-contents {
    margin-top: calc(515px + 24vw);
    margin-right: -90%;
    margin-left: -55%;
    margin-bottom: calc(-10px + 30vw);
    font-size: calc(75px + 10vw);
  }
  .responsive-imgs {
    max-width: 300%;
    width: 250%;
    height: auto;
  }
  
  .hero-containering {
    flex-direction: row;
  }
  }


@media only screen and (max-width: 672px) {
    .dark-league {
        color: rgb(255, 255, 255);
        margin-left: 22%;
        margin-top: 65%;
        font-size: calc(30px + 10vw);
    }

    .hero-containering {
      display: flex;
      padding-top: 50%;
      padding-bottom: 40%;
  }

    .studios {
        font-size: calc(30px + 10vw);
        margin-left: 22%;
        margin-top: -12%;
    }

    .hero-contain {
      display: none;
    }

    .tag-line {
        font-size: calc(10px + 2vw);
        margin-left: 1%;
        margin-top: -15%;
        margin-bottom: 10%;
        font-weight: bold;
    }

    .image-contents {
        margin-top: calc(615px + 24vw);
        margin-right: -90%;
        margin-left: -110%;
        margin-bottom: calc(-70px + 30vw);
        font-size: calc(75px + 10vw);
    }
    .responsive-imgs {
        max-width: 300%;
        margin-left: 15%;
        width: auto;
        height: auto;
    }

    .hero-containering {
        flex-direction: row;
        margin-bottom: 5%;
    }

    .abt {
        margin-top: -110%;
        margin-bottom: 10%;
        font-size: small;
        margin-left: -110%;
        padding: 20%;
        text-align: justify;
    }
}

