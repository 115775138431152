.carousel-container {
    margin-left: 30px;
    margin-right: 0px;
    border-radius: 0%;
    margin-top: -5%;
    margin-bottom: -5%;
    align-items: center;
    justify-content: center;
    width: 96%;
    max-width: 200%;
    background-color: rgb(0, 0, 0);
    padding-bottom: 5%;
     /* Ensure the container takes up the full width of its parent */
  }


  .carousel-slide {
    align-items: center;
    justify-content: center;
    display: flex;
    width: auto;
    height: 75vh; /* Adjust the height as needed; 'vh' stands for viewport height */
    background-color: rgb(0, 0, 0);
  }

  
  

  .carousel-image {
    display:flex;
    width:100%;
    height: auto;
    object-fit:cover; /* Maintain the aspect ratio and cover the entire slide */
  }

  @media (max-width: 768px) {
    .carousel-container {
      margin-top: 100%;
      width: 89%;
      height: auto;
    }

    .carousel-slide {
      height: 15vh;
    }
    .carousel-image {
      display:flex;
      width:100%;
      height: auto;
      object-fit:cover; /* Maintain the aspect ratio and cover the entire slide */
    }

  }
  