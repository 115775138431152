
body {
  margin: 0;
  padding: 0;
  background: url('../public/Img/BLACK.png') no-repeat center center fixed;
  background-size: cover;
}

/* styles.css */

footer {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.social-icons a {
  color: white;
  font-size: 20px;
  margin: 0 10px;
  text-decoration: none;
}

.social-icons a:hover {
  color: rgb(255, 255, 255);
}

.Button a:hover::after {
  content: "";
  width: 30%;
  height: 5px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  bottom: -10px;
  left: 20px;
}

