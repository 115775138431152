.grid-contaiiner {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: -2%;
    margin-bottom: -1%;
    background-color: white;
  }
  
  .grid-rrow {
    display: flex;
    background-color: #ffffff;
    margin-right: 15%;
    margin-left: 10%;
  }
  
  .grid-ittem {
    flex: 5;
    border: 1px solid #ffffff;
    background-color:transparent;
    border: none;
    border-radius: 5%;
    padding: 5%;
    margin-left: 1%;
  }

  .br4 {
    color: rgb(0, 0, 0);
    font-size: 40px;
    font-family: 'bai-bold';
  }

  .paar {
    align-items: baseline;
    max-width: 200%;
    width: 200%;
    justify-content:center;
  }

  .moon-paar {
    max-width: 200%;
    width: 180%;
    margin-top: -25%;
  }

  @media (max-width: 768px) {
    .grid-rrow {
      display: flex;
      background-color: #ffffff;
      width: 100%;
    }

    .grid-contaiiner {
      width: 100%;
      margin-left: 0%;
    }

    .br4 {
      text-align: center;
      font-size: 15px;
      width: 100%;
    }
  }
  