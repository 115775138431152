.scarousel-container {
  margin-left: 15%;
    border: none;
    border-radius: 0%;
    margin-top: -30%;
    margin-bottom: 5%;
    align-items: center;
    justify-content: center;
    width: 1545px;
    max-width: 200%;
    padding: 5%;
    background-color: rgb(0, 0, 0);
     /* Ensure the container takes up the full width of its parent */
  }

  .scarousel-arrow {
    width: 50px; /* Increase the width */
    height: 1px; /* Increase the height */
    z-index: 1;
  }
  
  .scarousel-arrow::before {
    font-size: 50px; /* Increase the font size of the arrow icon */
    color: #ffffff;
  }
  
  .scarousel-arrow:hover::before {
    color: #555;
  }
  
  .scarousel-slide {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 600%;
    height: 70vh; /* Adjust the height as needed; 'vh' stands for viewport height */
    background-color: rgb(0, 0, 0);
  }

  .slick-dots li button:before {
    color: rgb(255, 255, 255);
    margin-top: -100%;
  }
  
  .scarousel-image {
    display:flex;
    width:auto;
    height: auto;
    object-fit:cover; /* Maintain the aspect ratio and cover the entire slide */
  }

  @media (max-width: 768px) {
    .scarousel-container {
      margin-top: 100%;
      width: 85%;
      height: auto;
      margin-left: 40%;
    }

    .scarousel-slide {
      height: 20vh;
    }

    .scarousel-arrow::before {
      font-size: 50px; /* Increase the font size of the arrow icon */
      color: #ffffff;
    }

    .scarousel-arrow {
      width: 50px; /* Increase the width */
      height:45px; /* Increase the height */
      z-index: 1;
    }
    
    .scarousel-arrow:hover::before {
      color: #555;
    }

  }
  