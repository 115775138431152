.grid-contaiiner {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: -2%;
    margin-bottom: -1%;
    background-color: white;
  }
  
  .grid-rrow2 {
    display: flex;
    background-color: transparent;
    margin-right: 15%;
    margin-left: -5%;
    margin-top: -5%;
  }
  
  .grid-ittem {
    flex: 5;
    border: 1px solid #ffffff;
    background-color:transparent;
    border: none;
    border-radius: 5%;
    padding: 5%;
    margin-left: 1%;
  }

  .br5 {
    color: rgb(0, 0, 0);
    font-size: 40px;
    font-family: 'bai-bold';
    margin-top: -5%;
  }

  .Media1 {
    align-items: baseline;
    max-width: 200%;
    width: 200%;
    justify-content:center;
    margin-top: 15%;
  }
  .Media2 {
    align-items: baseline;
    max-width: 200%;
    width: 200%;
    justify-content:center;
  }
  .Media3 {
    align-items: baseline;
    max-width: 200%;
    width: 200%;
    justify-content:center;
  }
  .Media4 {
    align-items: baseline;
    max-width: 500%;
    width: 300%;
    justify-content:center;
    margin-left: -45%;
    margin-top: 25%;
  }
  .Media5 {
    align-items: baseline;
    max-width: 200%;
    width: 200%;
    justify-content:center;
    margin-left: 15%;
    margin-top: -15%;
  }
  .Media6 {
    align-items: baseline;
    max-width: 200%;
    width: 140%;
    justify-content:center;
    margin-top: -35%;
  }

  .Media7 {
    align-items: baseline;
    max-width: 100%;
    width: 150%;
    justify-content:center;
    margin-left: 300%;
  }

  .Media8 {
    align-items: baseline;
    max-width: 200%;
    width: 150%;
    justify-content:center;
    margin-top: 15%;
    margin-left: 190%;
    
  }

  .Media9 {
    align-items: baseline;
    max-width: 200%;
    width: 150%;
    justify-content:center;
    margin-left: 120%;
  }

  .Media0 {
    align-items: baseline;
    max-width: 500%;
    width: 200%;
    justify-content:center;
    margin-top: 45%;
    margin-left: 50%;
  }

  .Media11 {
    align-items: baseline;
    max-width: 500%;
    width: 200%;
    justify-content:center;
    margin-top: 45%;
    margin-left: 15%;
  }

  .Media12 {
    align-items: baseline;
    max-width: 500%;
    width: 200%;
    justify-content:center;
    margin-top: 45%;
  }
  

  @media (max-width: 768px) {
    .grid-rrow {
      display: flex;
      background-color: #ffffff;
      width: 100%;
    }

    .grid-contaiiner {
      width: 100%;
      margin-left: 0%;
    }

    .br5 {
      text-align: center;
      font-size: 15px;
      width: 100%;
    }
  }
  