.div-vg {
    margin-bottom: 10%;
    padding: 5%;
    margin-left: 10%;
    padding-bottom: 5%;
}

.goal {
    font-family: 'bai-bold';
    font-size: 90px;
    color: rgb(0, 0, 0);
    /* opacity: 0;
    transform: translateX(-50%);
    animation: slideInText 2.5s ease-in-out forwards;
    animation-delay: 4s; */
    margin-left: 49%;
}
    .goals {
        color: rgb(0, 0, 0);
        font-size: 20px;
        font-family: 'bai-reg';
        width: 40%;
        margin-bottom: 10%;
        /* opacity: 0;
        transform: translateX(-50%);
        animation: slideInText 2.5s ease-in-out forwards;
        animation-delay: 4.5s; */
        margin-left: 50%;
        margin-top: -35%;
    }

.vision {
    font-family: 'bai-bold';
    font-size: 90px;
    color: rgb(0, 0, 0);
    /* opacity: 0;
    transform: translateX(-50%);
    animation: slideInText 2.5s ease-in-out forwards;
    animation-delay: 4s; */
    margin-left: 6.5%;
    margin-top: 20%;
    margin-bottom: 5%;
}
    .visions {
        color: rgb(0, 0, 0);
        font-size: 20px;
        font-family: 'bai-reg';
        width: 40%;
        margin-bottom: -20%;
        /* opacity: 0;
        transform: translateX(-50%);
        animation: slideInText 2.5s ease-in-out forwards;
        animation-delay: 4.5s; */
        padding-bottom: 5%;
        text-align: right;
        margin-left: 2%;
        margin-top: -15%;
    }

    .res-imgs1 {
      max-width: 100%;
      width: 40%;
      margin-left: -5%;
      margin-bottom: 20%;
      margin-top: -20%;
      /* opacity: 0;
      transform: scale(0.9);
      animation: fadeInScale 1s ease-in-out forwards;
      animation-delay: 3s; */
  }

  .res-imgs2 {
    max-width: 100%;
    width: 40%;
    margin-top: -25%;
    margin-left: 55%;
    /* opacity: 0;
    transform: scale(0.9);
    animation: fadeInScale 1s ease-in-out forwards;
    animation-delay: 3s; */
}

    @keyframes slideIn {
        from {
          opacity: 0;
          transform: translateX(-100%);
        }
        to {
          opacity: 1;
          transform: translateX(0);
        }
      }
      
      @keyframes slideInText {
        from {
          opacity: 0;
          transform: translateX(-50%);
        }
        to {
          opacity: 1;
          transform: translateX(0);
        }
      }

@media (max-width: 768px) {
  .goal {
    font-size: 60px;
    margin-top: 90%;
    margin-left: -30%;
    text-align: left;
    width: 50%;
  }
    .goals {
        color: rgb(0, 0, 0);
        font-size: 15px;
        font-family: 'bai-reg';
        width: 90%;
        margin-left: -30%;
        margin-top: -10%;
    }
  .vision {
    font-size: 55px;
    text-align: left;
    margin-top: 55%;
    margin-left: 49%;
    margin-right: 20%;
    margin-bottom: -5%;
  }
    .visions {
        color: rgb(0, 0, 0);
        font-size: 15px;
        font-family: 'bai-reg';
        width: 100%;
        margin-left: 8%;
        margin-top: 5%;
        padding-bottom: 20%;
    }

    .res-imgs1 {
      margin-top: -80%;
      margin-left: 35%;
      width: 80%;
  }
  .vis-div {
    margin-top: -5%;
  }

  .res-imgs2 {
    margin-left: -33%;
    margin-top: -100%;
      width: 80%;
  }
}