.grid-coontainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 0%;
  }
  
  .grid-roow {
    display: flex;
    background-color: #ffffff;
    width: 80%;
    margin-left: 2%;
  }
  
  .grid-iteem {
    flex: 5;
    border: 1px solid #ffffff;
    padding: none;
    margin: none;
    background-color: rgb(255, 255, 255);
    border: rgb(255, 255, 255);
    border-radius: 5%;
    margin-left: 5%;
    margin-right: -5%;
  }

  .predator-parr {
    margin-top: 0%;
    max-width: 100%;
    width: 80%;
    margin-left: 15%;
  }

  .arena-parr {
    margin-top: 10%;
    max-width: 100%;
    margin-left: 40%;
    width: 60%;
  }

  .con-parr {
    margin-top: 5%;
    max-width: 100%;
    width: 90%;
    margin-left: -25%;
  }

  .con1-parr {
    margin-top: 10%;
    max-width: 100%;
    width: 70%;
    margin-left: 5%;
  }

  .mwell-parr {
    margin-top: 8%;
    max-width: 100%;
    width: 70%;
    margin-left: 10%;
  }

  .mwell1-parr {
    margin-top: 8%;
    max-width: 100%;
    width: 60%;
    margin-left: -5%;
  }

  .br2 {
    color: rgb(0, 0, 0);
    font-size: 40px;
    font-family: 'bai-bold';
    text-align: center;
  }

  .parr {
    align-items: baseline;
    max-width: 100%;
    width: 100%;
    height: auto;
    margin-left: -5%;
  }
  
  @media (max-width: 768px) {
    .grid-roow {
      display: flex;
      background-color: #ffffff;
      width: 100%;
      text-align: center;
      margin-left: -2%;
    }
    
    .grid-coontainer {
      width: 100%;
      text-align: center;
    }

    .br2 {
      color: rgb(0, 0, 0);
      font-size: 15px;
      font-family: 'bai-bold';
      text-align: center;
      width: 100%;
    }
  }