.ee-div {
    background-color: rgb(255, 255, 255);
    padding-bottom: 0%;
    margin-top: -5%;
}

.cast-header {
    margin-top: 20%;
    text-align: center;
    font-size: 50px;
}

.ee-div2 {
    background-color: rgb(0, 0, 0);
    padding-bottom: 35%;
}

.ee-img {
    width: 100%;
    margin-top: 0%;
}

.ee-img4 {
    width: 100%;
    margin-bottom: -60%;
    margin-left: -15%;
    margin-top: -95%;
}

.ee-img2 {
    width: 50%;
    margin-left: 25%;
    margin-top: 5%;
}

.ee-img3 {
    width: 30%;
    margin-left: 0%;
    position: absolute;
    margin-top: -60%;
}

.ee-desc {
    font-family: 'bai-light';
    color: rgb(0, 0, 0);
    width: 50%;
    font-size: 20px;
    text-align: center;
    justify-content: center;
    margin-left: 25%;
    margin-top: 5%;
    margin-bottom: -15%;
}

.ee-desc2 {
    font-family: 'bai-light';
    color: rgb(0, 0, 0);
    width: 60%;
    font-size: 30px;
    text-align: right;
    justify-content: center;
    margin-left: 15%;
    margin-top: -15%;
    margin-bottom: 50%;
}

.ee-desc3 {
    font-family: 'bai-light';
    color: rgb(255, 255, 255);
    width: 50%;
    font-size: 20px;
    text-align: justify;
    justify-content: center;
    margin-left: 25%;
    margin-top: 2%;
    margin-bottom: -25%;
}

.highlight {
    font-family: 'bai-bold';
}

.highlight2 {
    font-family: 'bai-bold';
    font-size: 40px;
}

.ee-header {
    color: rgb(0, 0, 0);
    font-family: 'bai-bold';
    font-size: 50px;
    text-align: center;
    margin-top: 5%;
}

.ee-header2 {
    color: rgb(255, 255, 255);
    font-family: 'bai-bold';
    font-size: 30px;
    text-align: center;
    margin-top: 5%;
}

.ee-box {
    width: 60%;
    margin-left: 40%;
    margin-top: 15%;
}

.ee-box2 {
    margin-left: 40%;
    margin-top: -40%;
    margin-bottom: 30%;
    position: absolute;
}

.tnc-div {
    background-color: black;
    text-align: center;
    margin-bottom: 10%;
}

.tnc-img {
    width: 100%;
    margin-top: -5%;
}

.tnc-head {
    color: white;
    font-size: 150px;
    font-family: "pubg-sans";
    text-align: center;
    margin-bottom: -15%;
}

.tnc-desc {
    color: white;
    font-family: "bai-light";
    text-align: left;
    font-size: 25px;
    width: 50%;
    justify-self: center;
}

.tnc-desc1 {
    color: white;
    font-family: "bai-light";
    text-align: left;
    font-size: 25px;
    width: 50%;
    text-align: center;
    justify-self: center;
    margin-top: 5%;
}

.card09 {
    margin-bottom: 5%;
    justify-content: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 790px;
    height: 254px;
    background: transparent;
    border: 1px solid white;
    -webkit-box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
            box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-weight: bolder;
    color: rgb(255, 255, 255);
  }
  
  .card09:hover {
    border: 1px solid black;
    -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
            transform: scale(1.05);
  }
  
  .card09:active {
    -webkit-transform: scale(0.95) rotateZ(1.7deg);
        -ms-transform: scale(0.95) rotate(1.7deg);
            transform: scale(0.95) rotateZ(1.7deg);
  }

  .div-tnc {
    background-color: rgb(0, 0, 0);
    justify-content: center;
  }

  .button {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    background: none;
    color: #0f1923;
    cursor: pointer;
    position: relative;
    padding: 8px;
    margin-top: 0%;
    margin-bottom: 50px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 24px;
    transition: all .15s ease;
  }
  
  .button::before,
  .button::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    left: 0;
    height: calc(50% - 5px);
    border: 1px solid #7D8082;
    transition: all .15s ease;
  }
  
  .button::before {
    top: 0;
    border-bottom-width: 0;
  }
  
  .button::after {
    bottom: 0;
    border-top-width: 0;
  }
  
  .button:active,
  .button:focus {
    outline: none;
  }
  
  .button:active::before,
  .button:active::after {
    right: 3px;
    left: 3px;
  }
  
  .button:active::before {
    top: 3px;
  }
  
  .button:active::after {
    bottom: 3px;
  }
  
  .button_lg {
    position: relative;
    display: block;
    padding: 20px 50px;
    color: #fff;
    background-color: #0f1923;
    overflow: hidden;
    box-shadow: inset 0px 0px 0px 1px transparent;
  }
  
  .button_lg::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 2px;
    background-color: #0f1923;
  }
  
  .button_lg::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 4px;
    height: 4px;
    background-color: #0f1923;
    transition: all .2s ease;
  }
  
  .button_sl {
    display: block;
    position: absolute;
    top: 0;
    bottom: -1px;
    left: -8px;
    width: 0;
    background-color: #ffe23f;
    transform: skew(-15deg);
    transition: all .2s ease;
  }
  
  .button_text {
    position: relative;
  }
  
  .button:hover {
    color: #0f1923;
  }
  
  .button:hover .button_sl {
    width: calc(100% + 15px);
  }
  
  .button:hover .button_lg::after {
    background-color: #fff;
  }
  

@media (max-width: 768px) {
    .ee-img {
        width: 100%;
        margin-top: 0%;
    }

    .ee-header2 {
        padding-top: 20%;
        text-align: left;
        margin-left: 5%;
     }

    .ee-img2 {
        width: 80%;
        margin-left: 10%;
        margin-bottom: -15%;
    }

    .ee-desc {
        width: 90%;
        margin-left: 5%;
        font-size: 15px;
        margin-bottom: 65%;
        margin-top: 7%;
        text-align: left;
    }

    .ee-header {
        color: rgb(0, 0, 0);
        font-family: 'bai-bold';
        font-size: 30px;
        text-align: center;
    }

    .ee-box {
        width: 60%;
        margin-left: 40%;
        margin-top: 15%;
    }

    .ee-desc2 {
        font-family: 'bai-light';
        color: rgb(0, 0, 0);
        font-size: 12px;
        width: 135%;
        margin-left: -50%;
        padding-bottom: 30%;
    }

    .highlight2 {
        font-family: 'bai-bold';
        font-size: 15px;
    }

    .ee-desc3 {
        width: 85%;
        margin-left: 5%;
        font-size: 15px;
        text-align: left;
    }

    .ee-box2 {
        margin-top: -50%;
        padding-bottom: 25%;
    }

    .ee-img3 {
        width: 30%;
        margin-left: 0%;
        position: absolute;
        margin-top: -50%;
    }

    .ee-div2 {
        padding-top: 15%;
        padding-bottom: 55%;
    }
    .cast-header {
        margin-top: -60%;
        font-size: 30px;
    }

    .tnc-head {
        font-size: 35px;
    }

    .tnc-desc {
        margin-left: 5%;
        font-size: 15px;
        width: 90%;
    }

    .tnc-desc1 {
        margin-top: 15%;
        font-size: 25px;
        width: 90%;
    }
}