.rsvp-div1{
  background: url('/src/components/TNCEE/Registration/eetnc.png') no-repeat center center fixed;
  
}

.tnc-img-logo {
  margin-top: 10%;
  width: 40%;
  margin-left: 30%;
}

.act {
  font-family: "bai-light";
  font-style: italic;
}

.heder {
  font-family: "pubg-sans";
  font-size: 70px;
  font-weight: lighter;
}

.ee-img1 {
  width: 100%;
  margin-top: 5%;
}

.rsvp-form {
  color: white;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 5px solid #ffe23f;;
  border-radius: 8px;
  background-color: black;
}

.rsvp-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.rsvp-h1 {
  color: white;
  font-size: 20px;
}

.check-1 {
  margin-bottom: 10%;
}

.profile-rsvp {
  margin-bottom: 15%;
  margin-top: 10%;
}

.venue-rsvp {
  margin-bottom: 15%;
  font-family: "Bai-light";
  font-weight: normal;
}

.submitt-btn1 {
  margin-top: 10%;
}

.rsvp-h2 {
  color: white;
  font-size: 19px;
  margin-bottom: 10%;
  font-family: "Bai-light";
  justify-self: center;
}

.rsvp-form label {
  display: block;
  margin-bottom: 10px;
}

.error-message {
  color: red;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.rsvp-form label input[type="number"] {
  width: 95%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.rsvp-form input[type="text"],
.rsvp-form input[type="email"],
.rsvp-form input[type="tel"] {
  width: 95%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.fancy {
  background-color: transparent;
  border: 2px solid #f7f7f7;
  border-radius: 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  letter-spacing: 0.05em;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 1.25em 5em;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  user-select: none;
  font-size: 15px;
  margin-top: 5%;
 }
 
 .fancy::before {
  content: " ";
  width: 1.5625rem;
  height: 2px;
  background: rgb(255, 255, 255);
  top: 50%;
  left: 1.5em;
  position: absolute;
  transform: translateY(-50%);
  transform-origin: center;
  transition: background 0.3s linear, width 0.3s linear;
 }
 
 .fancy .text {
  font-size: 1.125em;
  line-height: 1.33333em;
  padding-left: 2em;
  display: block;
  text-align: left;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
 }
 
 .fancy .top-key {
  height: 2px;
  width: 1.5625rem;
  top: -2px;
  left: 0.625rem;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, left 0.3s ease-out;
 }
 
 .fancy .bottom-key-1 {
  height: 2px;
  width: 1.5625rem;
  right: 1.875rem;
  bottom: -2px;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, right 0.3s ease-out;
 }
 
 .fancy .bottom-key-2 {
  height: 2px;
  width: 0.625rem;
  right: 0.625rem;
  bottom: -2px;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, right 0.3s ease-out;
 }
 
 .fancy:hover {
  color: rgb(0, 0, 0);
  background: rgb(255, 255, 255);
 }
 
 .fancy:hover::before {
  width: 0.9375rem;
  background: rgb(0, 0, 0);
 }
 
 .fancy:hover .text {
  color: rgb(0, 0, 0);
  padding-left: 1.5em;
 }
 
 .fancy:hover .top-key {
  left: -2px;
  width: 0px;
 }
 
 .fancy:hover .bottom-key-1,
  .fancy:hover .bottom-key-2 {
  right: 0;
  width: 0;
 }

.disclaimer-warning {
  color: red;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .ee-img1 {
    margin-top: 20%;
  }
}
