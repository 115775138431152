.pba-div {
    background-color: rgb(0, 0, 0);
    padding-bottom: 0%;
}

.pba-div2 {
    background-color: rgb(0, 0, 0);
    display: flex;
    margin-left: 30%;
}

.pba-div3 {
    background-color: rgb(0, 0, 0);
    display: flex;
    margin-left: 30%;
    margin-top: 20%;
}

.pba-div4 {
    background-color: rgb(0, 0, 0);
    margin-top: 25%;
    margin-bottom: -20%;
    margin-left: 10%;
    justify-content: center;
}

.pba-img {
    width: 100%;
    margin-top: 0%;
}

.pba-desc {
    font-family: 'bai-light';
    color: rgb(255, 255, 255);
    width: 50%;
    font-size: 20px;
    text-align: left;
    justify-content: center;
    margin-left: 25%;
    margin-top: -4%;
    margin-bottom: -20%;
}

.pba-highlight {
    font-family: 'bai-bold';
}

.pba-header {
    color: rgb(0, 0, 0);
    font-family: 'bai-bold';
    font-size: 50px;
    text-align: center;
    padding-bottom: 2%;
    margin-bottom: -55%;
}

.pba-box {
    width: 60%;
    margin-left: 40%;
}

.pba-box2 {
    margin-left: 80%;
    margin-bottom: -70%;
}

.pba-box3 {
    margin-top: -50%;
    margin-bottom: -40%;
}

.pba-desc2 {
    font-family: 'bai-light';
    color: rgb(0, 0, 0);
    width: 60%;
    font-size: 30px;
    text-align: center;
    justify-content: center;
    margin-left: -13%;
    margin-top: -20%;
}

.pba-header {
    color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
    font-family: 'bai-bold';
    font-size: 50px;
    text-align: center;
    margin-top: 25%;
    margin-bottom: 0%;
    padding-top: 3%;
}

.pba-img2 {
    width: 100%;
}

.pba-img8 {
    width: 90%;
    justify-content: center;
}

.pba-img10 {
    width: 30%;
    justify-content: center;
}

.pba-img11 {
    width: 80%;
    justify-content: center;
}

.pba-img9 {
    width: 70%;
    justify-content: center;
}

.pba-img3 {
    width: 70%;
    justify-content: center;
    margin-left: 15%;
}

.pba-img4 {
    width: 150%;
    margin-top: 0%;
}

@media (max-width: 768px) {
    .pba-desc {
        width: 90%;
        text-align: left;
        font-size: 15px;
        margin-left: 5%;
        margin-top: 1%;
    }

    .pba-box2 {
        margin-left: 80%;
        margin-top: 20%;
    }

    .pba-box3 {
        margin-top: -75%;
        margin-bottom: -120%;
        width: 90%;
    }

    .pba-div3 {
        margin-top: 130%;
        margin-left: 0%;
    }

    .pba-div2 {
        margin-left: 0%;
    }

    .pba-header {
        font-size: 30px;
        margin-top: 35%;
        margin-bottom: -35%;
    }
    .pba-img10 {
        width: 30%;
        justify-content: center;
    }

    .pba-div4 {
        margin-top: 35%;
    }
    
    .pba-img11 {
        width: 80%;
        justify-content: center;
    }
}